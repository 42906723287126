import React from 'react';

const MigrationServices = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Migration Services</h1>
      <p className="text-lg mb-4">
        Seamlessly transition to the cloud with TheInvictus.ai's comprehensive migration services.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Cloud Migration:</strong> Application and Data Center Migrations to Hybrid or Multi-Cloud.</li>
        <li><strong>Database Migration:</strong> On-Prem to Cloud, Database Replatforming, and Data Validation.</li>
        <li><strong>Workload Migration:</strong> Lift & Shift, Rehosting, Refactoring, and Optimization.</li>
        <li><strong>Post-Migration Support:</strong> Performance Tuning, Managed Services, and Continuous Optimization.</li>
      </ul>
    </div>
  );
};

export default MigrationServices;

