import React from 'react';

const WhyChooseUs = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Why Choose Us</h1>
      <p className="text-lg mb-4">
        Discover what sets TheInvictus.ai apart from the rest.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Innovation & Technology Expertise:</strong> Leading expertise in AI/ML, Cloud, and Emerging Tech.</li>
        <li><strong>Customer-Centric Approach:</strong> Tailored solutions focusing on the client’s unique needs.</li>
        <li><strong>Proven Success in Key Industries:</strong> A track record of success across diverse sectors.</li>
        <li><strong>Certifications & Standards:</strong> Compliance with ISO, SOC 2, HIPAA, and more.</li>
        <li><strong>Flexible Engagement Models:</strong> Choose from a range of flexible service models.</li>
        <li><strong>Multi-Cloud Expertise:</strong> Extensive experience across AWS, Azure, GCP, and hybrid/multi-cloud.</li>
        <li><strong>Thought Leadership:</strong> Regularly published blogs, whitepapers, and webinars.</li>
        <li><strong>Partner Network:</strong> Strategic alliances with top cloud providers and technology vendors.</li>
      </ul>
    </div>
  );
};

export default WhyChooseUs;

