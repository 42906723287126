import React from 'react';

const ManagedServices = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Managed Services</h1>
      <p className="text-lg mb-4">
        Ensure peak performance with TheInvictus.ai’s end-to-end managed services.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Cloud Operations & Support:</strong> 24/7 Monitoring, SLA Management, and Disaster Recovery.</li>
        <li><strong>Cloud Infrastructure Management:</strong> IaC, Patching, and Performance Optimization.</li>
        <li><strong>Compliance & Security Management:</strong> Continuous Monitoring, Audits, and CSPM.</li>
        <li><strong>Database Management:</strong> Database Monitoring, Backup, and Restore Solutions.</li>
      </ul>
    </div>
  );
};

export default ManagedServices;

