import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CloudNative from './CloudNative';
import HybridCloudArchitecture from './HybridCloudArchitecture';

const CloudDesignPage = () => {
  const [activeTab, setActiveTab] = useState('cloudnative');

  // Function to render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'cloudnative':
        return <CloudNative />;
      case 'hybridcloud':
        return <HybridCloudArchitecture />;
      default:
        return <CloudNative />;
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Breadcrumb Navigation */}
      <nav className="text-sm mb-4">
        <Link to="/" className="text-gray-600 hover:text-blue-500">Home</Link> &gt;{' '}
        <Link to="/architecture" className="text-gray-600 hover:text-blue-500">Architecture</Link> &gt;{' '}
        <Link to="/architecture/cloud-design" className="text-gray-600 hover:text-blue-500">Cloud Design</Link>
      </nav>

      {/* Tab Navigation */}
      <div className="flex border-b mb-6">
        <button
          className={`p-4 ${
            activeTab === 'cloudnative' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('cloudnative')}
        >
          Cloud Native
        </button>
        <button
          className={`p-4 ${
            activeTab === 'hybridcloud' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('hybridcloud')}
        >
          Hybrid Cloud
        </button>
      </div>

      {/* Render Content Based on Active Tab */}
      <div>{renderContent()}</div>
    </div>
  );
};

export default CloudDesignPage;
