import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import Home from './pages/Home';
import Strategy from './pages/Strategy';
import Architecture from './pages/Architecture';
import MigrationServices from './pages/MigrationServices';
import ManagedServices from './pages/ManagedServices';
import AI_ML from './pages/AI_ML';
import CloudAnalytics from './pages/CloudAnalytics';
import DevOps from './pages/DevOps';
import IndustrySolutions from './pages/IndustrySolutions';
import Training from './pages/Training';
import Innovation from './pages/Innovation';
import Resources from './pages/Resources';
import WhyChooseUs from './pages/WhyChooseUs';
import DigitalTransformation from './pages/Strategy/DigitalTransformation';
import ReadinessAssessment from './pages/Strategy/CloudAdoption/ReadinessAssessment';
import BusinessCaseDevelopment from './pages/Strategy/CloudAdoption/BusinessCaseDevelopment';
import CloudTransformationRoadmap from './pages/Strategy/CloudAdoption/CloudTransformationRoadmap';
import CloudAdoptionPage from './pages/Strategy/CloudAdoption/CloudAdoptionPage';
import CostOptimizationPage from './pages/Strategy/CostOptimization/CostOptimizationPage';
import CostAnalysisAssessment from './pages/Strategy/CostOptimization/CostAnalysisAssessment';
import FinancialModelingBudgeting from './pages/Strategy/CostOptimization/FinancialModelingBudgeting';
import CloudCostGovernance from './pages/Strategy/CostOptimization/CloudCostGovernance';

import BlogsPage from './pages/Resources/Blogs/BlogsPage';
import ImpactOfBroadcomsAcquisition from './pages/Resources/Blogs/ImpactOfBroadcomsAcquisition';
import AchievingEfficientAWSManagement from './pages/Resources/Blogs/AchievingEfficientAWSManagement';

import AIDrivenCloudOptimization from './pages/Resources/Whitepapers/AIDrivenCloudOptimization';

import Healthcare from './pages/Industries/Healthcare';
import Finance from './pages/Industries/Finance';
import Retail from './pages/Industries/Retail';

import CloudDesignPage from './pages/Architecture/CloudDesign/CloudDesignPage';
import CloudNative from './pages/Architecture/CloudDesign/CloudNative';
import HybridCloudArchitecture from './pages/Architecture/CloudDesign/HybridCloudArchitecture';

import DataArchitecturePage from './pages/Architecture/DataArchitecture/DataArchitecturePage';
import DataLakesWarehousing from './pages/Architecture/DataArchitecture/DataLakesWarehousing';

function App() {
  const pages = [
    { id: 1, title: 'Cloud Adoption', path: '/strategy' },
    { id: 2, title: 'Cost Optimization', path: '/strategy' },
    { id: 3, title: 'Digital Transformation', path: '/strategy' },
    { id: 4, title: 'Cloud Design', path: '/architecture' },
    { id: 5, title: 'Data Architecture', path: '/architecture' },
    { id: 6, title: 'Predictive Analytics', path: '/ai-ml' },
    { id: 7, title: 'CI/CD Pipelines', path: '/devops' },
    { id: 8, title: 'Infrastructure Management', path: '/devops' },
    // Add more pages as necessary
  ];

  return (
    <Router>
      <div className="bg-gray-100 text-gray-900 min-h-screen font-sans">
        <Navbar pages={pages} />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<AboutUs />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/strategy" element={<Strategy />} />
          <Route path="/architecture" element={<Architecture />} />
          <Route path="/migration-services" element={<MigrationServices />} />
          <Route path="/managed-services" element={<ManagedServices />} />
          <Route path="/ai-ml" element={<AI_ML />} />
          <Route path="/cloud-analytics" element={<CloudAnalytics />} />
          <Route path="/devops" element={<DevOps />} />
          <Route path="/industry-solutions" element={<IndustrySolutions />} />
          <Route path="/training" element={<Training />} />
          <Route path="/innovation" element={<Innovation />} />
          <Route path="/resources" element={<Resources />} />
          <Route path="/why-choose-us" element={<WhyChooseUs />} />
          <Route path="/strategy/cloud-adoption" element={<CloudAdoptionPage />} />
          <Route path="/strategy/cost-optimization" element={<CostOptimizationPage />} />
          <Route path="/strategy/digital-transformation" element={<DigitalTransformation />} />
          <Route path="/architecture/cloud-design" element={<CloudDesignPage />} />

          {/* Sub-Category Pages for Cloud Adoption */}
          <Route path="/strategy/cloud-adoption/readiness-assessment" element={<ReadinessAssessment />} />
          <Route path="/strategy/cloud-adoption/business-case-development" element={<BusinessCaseDevelopment />} />
          <Route path="/strategy/cloud-adoption/cloud-transformation-roadmap" element={<CloudTransformationRoadmap />} />
          <Route path="/strategy/cost-optimization/cost-analysis-assessment" element={<CostAnalysisAssessment />} />
          <Route path="/strategy/cost-optimization/financial-modeling-budgeting" element={<FinancialModelingBudgeting />} />
          <Route path="/strategy/cost-optimization/cloud-cost-governance" element={<CloudCostGovernance />} />

          <Route path="/architecture/cloud-design/cloud-native" element={<CloudNative />} />
          <Route path="/architecture/hybrid-cloud" component={HybridCloudArchitecture} />

          <Route path="/architecture/data-architecture" element={<DataArchitecturePage />} />
          <Route path="/architecture/datalakes-warehousing" element={<DataLakesWarehousing />} />

          {/* New Blog Routes */}
          <Route path="/resources/blogs" element={<BlogsPage />} />
          <Route path="/resources/blogs/impact-of-broadcom-acquisition" element={<ImpactOfBroadcomsAcquisition />} />
          <Route path="/resources/blogs/achieving-efficient-aws-management" element={<AchievingEfficientAWSManagement />} />

          {/* New Whitepaper Routes */}
          <Route path="/resources/whitepaper/ai-driven-cloud-optimization" element={<AIDrivenCloudOptimization />} />

          {/* Industries */}
          <Route path="/industries-healthcare" element={<Healthcare />} />
          <Route path="/industries-finance" element={<Finance />} />
          <Route path="/industries-retail" element={<Retail />} />

        </Routes>
        <Footer /> {/* Use Footer at the bottom */}
      </div>
    </Router>
  );
}

export default App;

