import React from 'react';
import CTAButton from '../components/CTAButton';
import ContactForm from '../components/ContactForm';

const Strategy = () => {
  const [showContactForm, setShowContactForm] = React.useState(false);

  // Handler to show the contact form when CTA is clicked
  const handleConsultationClick = () => {
    setShowContactForm(true);
  };

  // Handler to close the contact form
  const handleCloseForm = () => {
    setShowContactForm(false);
  };

  return (
    <div className="container mx-auto p-6">
      {/* Page Header */}
      <div className="mb-6">
        <h1 className="text-4xl font-semibold mb-4 text-left">Cloud Strategy</h1>
        <p className="text-lg text-gray-600">
          A strategic approach to cloud adoption, optimization, and transformation, designed to meet
          the unique needs of organizations across industries.
        </p>
      </div>

      {/* Phased Approach Overview */}
      <div className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">Phased Approach</h2>
        <ul className="list-disc pl-6">
          <li className="mb-3">
            <strong>Assessment:</strong> Evaluate current infrastructure, organizational readiness, and
            strategic goals. This includes technical audits, cloud readiness evaluations, and ROI projections.
          </li>
          <li className="mb-3">
            <strong>Migration:</strong> Implement a seamless migration plan that addresses data transfer,
            integration, and minimal disruption to ongoing operations. Include best practices for both lift-and-shift
            and cloud-native migrations.
          </li>
          <li className="mb-3">
            <strong>Optimization:</strong> Optimize cloud usage for cost efficiency, performance improvements,
            and advanced capabilities, including AI-driven optimizations and serverless architecture.
          </li>
          <li className="mb-3">
            <strong>Monitoring:</strong> Continuous monitoring and governance to maintain compliance,
            security, and operational efficiency, leveraging multi-cloud tools if needed.
          </li>
        </ul>
      </div>

      {/* Key Areas of Focus */}
      <div className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">Key Areas of Focus</h2>
        <div className="space-y-6">
          <div>
            <h3 className="text-2xl font-semibold mb-2">Cloud Adoption</h3>
            <p>
              Tailored adoption strategies for small businesses and enterprises, considering business goals,
              technical readiness, and cost-efficiency. Guidance on hybrid and multi-cloud environments.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Cost Optimization</h3>
            <p>
              Focus on reducing costs through optimization of infrastructure, SaaS, and licensing. Detailed
              strategies for cloud cost governance, financial modeling, and budget planning using FinOps.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Digital Transformation</h3>
            <p>
              Drive digital innovation with cloud-native services, modern application development, and
              data-driven decision-making. Address Industry 4.0 and intelligent automation using advanced
              analytics and AI.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Cloud Governance & Security</h3>
            <p>
              Establish a governance framework to manage cloud operations, enforce security best practices,
              and ensure compliance with industry regulations. Includes zero-trust models and security policies.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Multi-Cloud Management</h3>
            <p>
              Optimize and manage multi-cloud deployments for flexibility, avoiding vendor lock-in, and
              ensuring seamless operation across AWS, Azure, GCP, or other cloud providers.
            </p>
          </div>
        </div>
      </div>

      {/* Industry-Specific Use Cases */}
      <div className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">Industry-Specific Use Cases</h2>
        <div className="space-y-6">
          <div>
            <h3 className="text-2xl font-semibold mb-2">Healthcare</h3>
            <p>
              Utilize cloud platforms to improve patient care, data privacy, and interoperability. Case studies
              include secure health information exchanges, remote patient monitoring, and AI-driven diagnostics.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Finance</h3>
            <p>
              Drive digital banking innovation and meet stringent regulatory requirements. Includes use cases
              like fraud detection, customer insights, and AI-powered financial modeling.
            </p>
          </div>
          <div>
            <h3 className="text-2xl font-semibold mb-2">Manufacturing</h3>
            <p>
              Transform manufacturing processes with IoT, smart factories, and cloud-integrated supply chain
              management. Case studies include predictive maintenance and Industry 4.0 planning.
            </p>
          </div>
        </div>
      </div>

      {/* Technical & Business Considerations */}
      <div className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">Technical & Business Considerations</h2>
        <ul className="list-disc pl-6">
          <li className="mb-3">
            <strong>Security & Compliance:</strong> Address cloud security risks with zero-trust architecture, data
            encryption, and compliance with industry standards like GDPR, HIPAA, and PCI-DSS.
          </li>
          <li className="mb-3">
            <strong>Cost Management:</strong> Provide strategies for effective cost management, including
            reserved instances, workload scheduling, and scaling options.
          </li>
          <li className="mb-3">
            <strong>Performance Optimization:</strong> Ensure optimal performance through serverless architecture,
            containerization, and AI-driven workload management.
          </li>
          <li className="mb-3">
            <strong>Scalability:</strong> Develop a scalable architecture that supports future growth and integrates
            with emerging technologies such as AI, IoT, and edge computing.
          </li>
        </ul>
      </div>

      {/* Emerging Trends & Future Outlook */}
      <div className="mb-10">
        <h2 className="text-3xl font-semibold mb-4">Emerging Trends & Future Outlook</h2>
        <p>
          Stay ahead of the curve by exploring trends like AI-driven cloud optimization, serverless
          computing, container orchestration, and advancements in edge computing. Prepare for a future
          where cloud technology continues to transform industries.
        </p>
      </div>

      {/* Call to Action */}
      <CTAButton
        text="Discuss Your Cloud Strategy"
        subtext="Contact Invictus to build a customized cloud strategy tailored to your business needs. Let’s make your cloud journey successful."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}
    </div>
  );
};

export default Strategy;
