import React from 'react';

const Innovation = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Innovation & Emerging Tech</h1>
      <p className="text-lg mb-4">
        Embrace cutting-edge technologies to stay ahead in a rapidly evolving landscape.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>IoT & Edge Computing:</strong> IoT Strategy, Edge Device Management, and IoT Analytics.</li>
        <li><strong>Blockchain & Web3:</strong> Smart Contracts, Blockchain Strategy, and Digital Assets.</li>
        <li><strong>Quantum Computing:</strong> Quantum Readiness, Algorithm Development, and Quantum Cloud.</li>
        <li><strong>Augmented & Virtual Reality:</strong> AR/VR Strategy, Training & Simulation, and AI/IoT Integration.</li>
      </ul>
    </div>
  );
};

export default Innovation;

