import React, { useState } from 'react';
import CTAButton from '../../components/CTAButton';
import ContactForm from '../../components/ContactForm';

const DigitalTransformation = () => {

  const [showContactForm, setShowContactForm] = useState(false);

  const handleTransformationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-6 text-center">Digital Transformation</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Drive Innovation with Digital Transformation</h2>
        <p className="mb-4">
          Digital transformation goes beyond technology; it's about reshaping business models to drive innovation,
          improve customer experience, and increase market competitiveness. At Invictus, we enable organizations
          to fully embrace digitalization, leveraging cloud technology to unlock new opportunities and streamline operations.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Key Benefits</h2>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Enhanced Customer Experience:</strong> Use advanced analytics and AI to gain insights into customer behavior, delivering personalized experiences.</li>
          <li><strong>Operational Efficiency:</strong> Automate routine tasks and processes, reducing human error and improving efficiency by up to 45%.</li>
          <li><strong>Agility & Innovation:</strong> Build a culture of continuous innovation, adapting quickly to changing market conditions and customer expectations.</li>
          <li><strong>Data-Driven Decision Making:</strong> Implement data analytics and BI tools for actionable insights, enabling informed decision-making.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Why Choose Invictus?</h2>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Proven Track Record:</strong> Extensive experience in guiding Fortune 500 companies through digital transformation journeys.</li>
          <li><strong>Agile Digital Framework:</strong> Utilize agile methodologies to drive fast, iterative progress with minimal disruption.</li>
          <li><strong>Customer-Centric Approach:</strong> Focus on enhancing the customer journey with targeted, data-driven strategies.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Real-World Example</h2>
        <p className="mb-4">
          In the healthcare sector, Invictus helped a hospital network implement a digital patient management system,
          reducing administrative workload by <strong>40%</strong> and enhancing patient satisfaction through real-time updates and analytics-driven care.
        </p>
      </div>

      {/* Call to Action using CTAButton */}
      <CTAButton
        text="Start Your Digital Transformation Journey"
        subtext="Let’s transform your business together. Speak to our experts and discover a customized digital transformation strategy."
        bgColor="bg-purple-600"
        hoverColor="bg-purple-700"
        onClick={handleTransformationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}

    </div>
  );
};

export default DigitalTransformation;
