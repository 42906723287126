import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Overview from './Overview';
import ReadinessAssessment from './ReadinessAssessment';
import BusinessCaseDevelopment from './BusinessCaseDevelopment';
import CloudTransformationRoadmap from './CloudTransformationRoadmap';

const CloudAdoptionPage = () => {
  const [activeTab, setActiveTab] = useState('overview');

  // Function to render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <Overview />;
      case 'readiness':
        return <ReadinessAssessment />;
      case 'businessCase':
        return <BusinessCaseDevelopment />;
      case 'roadmap':
        return <CloudTransformationRoadmap />;
      default:
        return <Overview />;
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Breadcrumb Navigation */}
      <nav className="text-sm mb-4">
        <Link to="/" className="text-gray-600 hover:text-blue-500">Home</Link> &gt;{' '}
        <Link to="/strategy" className="text-gray-600 hover:text-blue-500">Strategy</Link> &gt;{' '}
        <Link to="/strategy/cloud-adoption" className="text-gray-600 hover:text-blue-500">Cloud Adoption</Link>
      </nav>

      {/* Tab Navigation */}
      <div className="flex border-b mb-6">
        <button
          className={`p-4 ${
            activeTab === 'overview' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button
          className={`p-4 ${
            activeTab === 'readiness' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('readiness')}
        >
          Readiness Assessment
        </button>
        <button
          className={`p-4 ${
            activeTab === 'businessCase' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('businessCase')}
        >
          Business Case Development
        </button>
        <button
          className={`p-4 ${
            activeTab === 'roadmap' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('roadmap')}
        >
          Transformation Roadmap
        </button>
      </div>

      {/* Render Content Based on Active Tab */}
      <div>{renderContent()}</div>
    </div>
  );
};

export default CloudAdoptionPage;
