import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-black text-gray-300 py-10">
      <div className="container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8">
        {/* Column 1: About */}
        <div>
          <h3 className="text-xl font-bold mb-4 text-white">About Invictus</h3>
          <p className="mb-4">
            At Invictus, we focus on driving digital transformation and cloud innovation.
            Our solutions are tailored to empower enterprises in the modern era.
          </p>
          <Link to="/about" className="text-gray-400 hover:text-white">Learn More →</Link>
        </div>

        {/* Column 2: Quick Links */}
        <div>
          <h3 className="text-xl font-bold mb-4 text-white">Quick Links</h3>
          <ul>
            <li>
              <Link to="/services" className="hover:text-white">Services</Link>
            </li>
            <li>
              <Link to="/solutions" className="hover:text-white">Solutions</Link>
            </li>
            <li>
              <Link to="/resources" className="hover:text-white">Resources</Link>
            </li>
            <li>
              <Link to="/contact" className="hover:text-white">Contact Us</Link>
            </li>
          </ul>
        </div>

        {/* Column 3: Contact Info */}
        <div>
          <h3 className="text-xl font-bold mb-4 text-white">Contact Us</h3>
          <p>Email: <a href="mailto:info@theinvictus.ai" className="hover:text-white">info@theinvictus.ai</a></p>
          <p>Phone: <a href="tel:+1234567890" className="hover:text-white">+1 (469) 348-5583</a></p>
          <div className="mt-4">
            <a href="https://facebook.com" className="mr-4 hover:text-white">Facebook</a>
            <a href="https://twitter.com" className="mr-4 hover:text-white">Twitter</a>
            <a href="https://linkedin.com" className="hover:text-white">LinkedIn</a>
          </div>
        </div>
      </div>

      {/* Footer Bottom */}
      <div className="mt-10 border-t border-gray-700 pt-6 text-center text-gray-500">
        &copy; {new Date().getFullYear()} Invictus. All Rights Reserved.
      </div>
    </footer>
  );
};

export default Footer;
