import React, { useState } from 'react';
import CTAButton from '../../../components/CTAButton';
import ContactForm from '../../../components/ContactForm';

const CostAnalysisAssessment = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-4xl font-bold mb-6 text-left">Cost Analysis & Assessment</h2>
      <p className="mb-4">
        Conducting a comprehensive cost analysis and assessment is essential for optimizing cloud expenses
        while maintaining or improving performance and scalability. This analysis covers factors like resource
        utilization, pricing models, workload management, and the impact of different cloud services, including
        compute, storage, and networking.
      </p>

      {/* Cost Drivers Analysis */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Identifying Cost Drivers</h3>
        <p className="mb-4">
          Analyzing the primary cost drivers allows organizations to pinpoint areas for optimization. This includes
          evaluating:
        </p>
        <ul className="list-disc pl-8 space-y-2">
          <li>Resource utilization and allocation efficiency.</li>
          <li>Pricing models (on-demand, reserved instances, spot instances).</li>
          <li>Workload management and scheduling.</li>
          <li>Impact of different cloud services (compute, storage, networking).</li>
        </ul>
      </section>

      {/* Multi-Cloud Environments */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Multi-Cloud Environments</h3>
        <p className="mb-4">
          Managing costs in a multi-cloud environment involves prioritizing platforms like AWS, Azure, and GCP based
          on specific business requirements. This assessment includes:
        </p>
        <ul className="list-disc pl-8 space-y-2">
          <li>Utilizing native tools such as AWS Cost Explorer and Azure Cost Management.</li>
          <li>Leveraging third-party solutions for enhanced cost monitoring and governance.</li>
          <li>Automating cost tracking and resource optimization.</li>
          <li>Addressing compliance and regulatory constraints specific to the industry.</li>
        </ul>
      </section>

      {/* Cost Management Tools */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Cost Management Tools & Techniques</h3>
        <p className="mb-4">
          Leveraging the right cost management tools is crucial for effective monitoring and governance. Key tools and
          techniques include:
        </p>
        <ul className="list-disc pl-8 space-y-2">
          <li>AWS Cost Explorer, Azure Cost Management, and Google Cloud's Cost Management Tools.</li>
          <li>Third-party solutions like CloudHealth and CloudCheckr for multi-cloud environments.</li>
          <li>Implementing governance frameworks for cost allocation and budgeting.</li>
          <li>Using automation for right-sizing resources and scaling based on demand.</li>
        </ul>
      </section>

      {/* Benchmarks & KPIs */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Benchmarks & KPIs</h3>
        <p className="mb-4">
          Setting benchmarks and KPIs helps measure cost reduction and performance improvements. These metrics include:
        </p>
        <ul className="list-disc pl-8 space-y-2">
          <li>Cost savings percentage compared to baseline assessments.</li>
          <li>Resource utilization efficiency (CPU, memory, storage).</li>
          <li>Cost per workload or application.</li>
          <li>Time to cost recovery based on the implementation of optimization strategies.</li>
        </ul>
      </section>

      {/* Short-term & Long-term Goals */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Short-term & Long-term Goals</h3>
        <p className="mb-4">
          The cost analysis and assessment focus on achieving both short-term and long-term goals. These goals include:
        </p>
        <ul className="list-disc pl-8 space-y-2">
          <li>Immediate cost reduction through right-sizing and workload optimization.</li>
          <li>Long-term cost savings by shifting to optimal pricing models and using automation.</li>
          <li>Performance improvements through better resource allocation.</li>
          <li>Scalability adjustments based on projected business growth.</li>
        </ul>
      </section>

      {/* Compliance & Regulatory Considerations */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Compliance & Regulatory Considerations</h3>
        <p className="mb-4">
          Compliance and regulatory constraints play a critical role in cost optimization strategies. Industry-specific
          guidelines include:
        </p>
        <ul className="list-disc pl-8 space-y-2">
          <li>Adhering to data privacy regulations like GDPR, HIPAA, or PCI-DSS.</li>
          <li>Implementing secure and compliant resource configurations.</li>
          <li>Monitoring for regulatory changes that impact cost management.</li>
        </ul>
      </section>

      {/* Recommendations & Best Practices */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Recommendations & Best Practices</h3>
        <p className="mb-4">
          To optimize cloud costs effectively, consider these best practices:
        </p>
        <ul className="list-disc pl-8 space-y-2">
          <li>Leverage reserved instances for predictable workloads.</li>
          <li>Utilize spot instances for non-critical workloads to save costs.</li>
          <li>Automate resource management using infrastructure-as-code (IaC) tools.</li>
          <li>Regularly review and audit cloud expenses for continuous optimization.</li>
        </ul>
      </section>

      {/* Conclusion */}
      <section className="mb-8">
        <h3 className="text-2xl font-semibold mb-4">Conclusion</h3>
        <p>
          A comprehensive cost analysis and assessment is a vital component of any cloud strategy focused on cost
          optimization. By identifying cost drivers, leveraging the right tools, and implementing best practices,
          organizations can achieve significant cost savings while enhancing performance and scalability.
        </p>
      </section>

      {/* Call to Action */}
      <CTAButton
        text="Get Your Cost Analysis & Assessment"
        subtext="Gain actionable insights to reduce costs and boost efficiency across your cloud infrastructure."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}
    </div>
  );
};

export default CostAnalysisAssessment;
