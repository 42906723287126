import React from 'react';

const CTAButton = ({ text, subtext, bgColor, hoverColor, onClick }) => {
  return (
    <div className="text-center mt-10">
      <button
        onClick={onClick}
        className={`${bgColor} text-white px-6 py-3 rounded-lg shadow-lg hover:${hoverColor} transition-all`}
      >
        {text}
      </button>
      {subtext && <p className="mt-4 text-sm text-gray-600">{subtext}</p>}
    </div>
  );
};

export default CTAButton;
