// File: src/pages/Strategy/CloudAdoption/CloudTransformationRoadmap.js

import React, { useState } from 'react';
import CTAButton from '../../../components/CTAButton';
import ContactForm from '../../../components/ContactForm';

const CloudTransformationRoadmap = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-6 text-left">Cloud Adoption Transformation Roadmap</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Overview of the Roadmap</h2>
        <p className="mb-4">
          A successful cloud adoption requires a structured roadmap that aligns with your organization's specific goals.
          This roadmap will detail the phases, strategies, and steps necessary for a seamless transition to the cloud,
          tailored to your organization's size, preferred cloud platform, and desired outcomes.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Phase 1: Readiness Assessment</h2>
        <p className="mb-4">
          The first phase involves assessing your organization's cloud readiness. This includes analyzing existing infrastructure,
          identifying skill gaps, evaluating security posture, and understanding compliance requirements. A thorough assessment
          will identify key areas that need improvement before proceeding to migration planning.
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Infrastructure Analysis:</strong> Review current systems, hardware, and network configurations.</li>
          <li><strong>Skills Assessment:</strong> Identify gaps in technical skills related to cloud platforms and migration tools.</li>
          <li><strong>Security & Compliance Check:</strong> Evaluate current security measures and compliance requirements.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Phase 2: Migration Planning</h2>
        <p className="mb-4">
          This phase focuses on creating a comprehensive migration plan. Key tasks include choosing the right migration strategy,
          setting clear objectives, and preparing a detailed project timeline with key milestones.
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Strategy Selection:</strong> Decide between lift-and-shift, re-platforming, or cloud-native development based on your goals.</li>
          <li><strong>Project Timeline:</strong> Establish a timeline with clear phases, deliverables, and deadlines.</li>
          <li><strong>Cost Analysis & Budgeting:</strong> Create a budget estimate and analyze potential cost savings.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Phase 3: Cloud Architecture Design</h2>
        <p className="mb-4">
          Designing a cloud architecture that aligns with your business needs is essential. This phase involves defining the target
          cloud environment, addressing integration challenges, and choosing the appropriate tools and frameworks.
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Target Cloud Environment:</strong> Choose between single cloud (AWS, Azure, GCP) or MultiCloud strategy.</li>
          <li><strong>Integration Planning:</strong> Address how existing on-premises systems and legacy apps will integrate with the cloud.</li>
          <li><strong>Tool & Framework Selection:</strong> Choose tools and frameworks like DevOps, Agile, or ITIL for managing the cloud journey.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Phase 4: Implementation Phases</h2>
        <p className="mb-4">
          Implementation should be executed in a phased approach to minimize risks and ensure a smooth transition. Below are the key phases:
        </p>
        <ol className="list-decimal ml-8 space-y-2">
          <li><strong>Pilot Deployment:</strong> Start with a pilot project to validate the chosen strategy and identify potential issues.</li>
          <li><strong>Full-Scale Migration:</strong> Migrate critical applications and data with minimal disruption.</li>
          <li><strong>Testing & Validation:</strong> Perform extensive testing to ensure functionality, security, and compliance.</li>
        </ol>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Phase 5: Training & Skill Development</h2>
        <p className="mb-4">
          Equip your teams with the necessary skills to manage cloud infrastructure efficiently. Invest in training programs and
          certifications tailored to your preferred cloud platforms.
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Cloud Certifications:</strong> Encourage staff to obtain certifications (e.g., AWS Certified Solutions Architect, Azure Fundamentals).</li>
          <li><strong>Role-Based Training:</strong> Develop training programs based on roles, such as cloud engineers, DevOps specialists, and security experts.</li>
          <li><strong>Workshops & Labs:</strong> Conduct hands-on workshops and lab exercises for practical cloud management experience.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Phase 6: Governance & Cost Management</h2>
        <p className="mb-4">
          Implement governance frameworks to manage cloud resources effectively and control costs. Establish clear policies, procedures, and budgets.
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Cloud Governance Framework:</strong> Create a governance model to oversee cloud operations, resource allocation, and security.</li>
          <li><strong>Cost Management Tools:</strong> Use cloud financial management tools like AWS Cost Explorer, Azure Cost Management, or GCP's Cost Management.</li>
          <li><strong>Continuous Monitoring:</strong> Monitor cloud performance, security, and costs through automated tools and dashboards.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Phase 7: Post-Migration Optimization</h2>
        <p className="mb-4">
          After the migration, focus on optimizing the cloud environment for better performance, cost efficiency, and security.
          Implement continuous improvements to stay ahead of evolving business needs.
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Performance Optimization:</strong> Tune resources, adjust workloads, and automate scaling for optimal performance.</li>
          <li><strong>Cost Efficiency:</strong> Review cloud expenses and identify areas for further cost reductions through rightsizing and automation.</li>
          <li><strong>Security Enhancements:</strong> Strengthen security postures with updated policies, compliance checks, and vulnerability assessments.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Timelines & Key Milestones</h2>
        <p className="mb-4">
          Set realistic timelines for each phase and establish key milestones to track progress. Use project management tools to maintain visibility:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Phase Completion Dates:</strong> Assign target dates for each roadmap phase and monitor adherence.</li>
          <li><strong>Milestone Tracking:</strong> Track critical milestones, such as pilot completion, full migration, and post-migration reviews.</li>
          <li><strong>Reporting & Metrics:</strong> Generate reports based on key performance indicators (KPIs) to assess success.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Success Metrics & KPIs</h2>
        <p className="mb-4">
          Define metrics to measure the success of your cloud adoption and transformation journey. KPIs should align with your organizational goals:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Cost Savings:</strong> Measure actual cost reductions compared to projected savings.</li>
          <li><strong>Performance Improvements:</strong> Track performance metrics such as response times, uptime, and resource utilization.</li>
          <li><strong>Scalability Achievements:</strong> Evaluate scalability goals, including capacity increases and latency reductions.</li>
        </ul>
      </div>

      {/* Call to Action */}
      <CTAButton
        text="Plan Your Cloud Transformation"
        subtext="Contact Invictus to create a customized cloud transformation roadmap. Let us help you navigate your cloud journey with confidence."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}
    </div>
  );
};

export default CloudTransformationRoadmap;
