import React from 'react';
import HeroSection from '../components/HeroSection';

const Home = () => {
  return (
    <div>
      {/* Hero Section at the top of the Home page */}
      <HeroSection />

      {/* Additional Content Below the Hero Section */}
      <div className="container mx-auto p-6 mt-10">
        <h2 className="text-3xl font-bold mb-6 text-center">Welcome to Invictus</h2>
        <p className="text-lg mb-6 text-left">
          At Invictus, we specialize in cloud transformation, AI-driven solutions, and
          cutting-edge industry innovations. Our goal is to empower businesses to lead in the digital era.
        </p>

        {/* Services Highlight */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div className="p-6 bg-gray-100 rounded shadow-lg text-left">
            <h3 className="text-xl font-bold mb-4 text-center">Cloud Services</h3>
            <p>From cloud adoption to architecture, we offer end-to-end solutions for seamless digital transformation.</p>
          </div>
          <div className="p-6 bg-gray-100 rounded shadow-lg text-left">
            <h3 className="text-xl font-bold mb-4 text-center">AI/ML Solutions</h3>
            <p>Leverage advanced AI and machine learning models to gain actionable insights and drive innovation.</p>
          </div>
          <div className="p-6 bg-gray-100 rounded shadow-lg text-left">
            <h3 className="text-xl font-bold mb-4 text-center">Industry Expertise</h3>
            <p>Our team provides deep expertise across industries like finance, healthcare, retail, and more.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;


