import React, { useState } from 'react';
import CTAButton from '../../../components/CTAButton';
import ContactForm from '../../../components/ContactForm';

const ReadinessAssessment = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-6 text-left">Cloud Adoption Readiness Assessment</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Overview</h2>
        <p className="mb-4">
          Our Cloud Adoption Readiness Assessment is designed for organizations of all sizes and industries, providing an
          in-depth technical evaluation. The assessment aims to determine the readiness of your organization for cloud adoption,
          highlighting potential areas for improvement and crafting a tailored cloud strategy that aligns with your goals.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Evaluation Areas</h2>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Infrastructure Evaluation:</strong> Analyze your current infrastructure to identify compatibility with cloud platforms and potential challenges during migration.</li>
          <li><strong>Organizational Culture & Skills:</strong> Assess the readiness of your team, including skill gaps and cultural alignment with cloud adoption.</li>
          <li><strong>Security Posture:</strong> Evaluate the existing security framework, focusing on vulnerabilities, threat detection, and risk management.</li>
          <li><strong>Governance Framework:</strong> Review governance policies, processes, and standards to ensure proper cloud management and compliance.</li>
          <li><strong>Current Technology Stack:</strong> Audit the technology stack in use to determine cloud compatibility and required updates.</li>
          <li><strong>Compliance Requirements:</strong> Ensure the cloud strategy aligns with industry-specific regulations (e.g., HIPAA for healthcare, GDPR for data privacy).</li>
          <li><strong>Cloud Cost-Benefit Analysis:</strong> Provide a detailed breakdown of short-term and long-term costs, including cost-saving strategies for each migration phase.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Recommendations</h2>
        <p className="mb-4">
          Based on the assessment, we provide tailored recommendations for successful cloud adoption. These include:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Infrastructure Enhancements:</strong> Upgrade or replace legacy systems that may not be cloud-compatible.</li>
          <li><strong>Security Improvements:</strong> Implement a zero-trust security model and enhance threat detection mechanisms.</li>
          <li><strong>Governance Adjustments:</strong> Develop a cloud governance framework that aligns with industry standards.</li>
          <li><strong>Cost Optimization:</strong> Use cloud-native tools like AWS Cost Explorer, Azure Cost Management, and GCP Cost Calculator to manage expenses effectively.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Scoring & Maturity Model</h2>
        <p className="mb-4">
          Our assessment uses a customized Cloud Maturity Model to evaluate specific domains such as security, compliance,
          operational efficiency, and scalability. Each domain is scored using numerical values, color-coded indicators, and
          graphical representations for clarity.
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Security:</strong> Rated on a scale from 1-10, with color-coded indicators for vulnerabilities (e.g., red for critical risks).</li>
          <li><strong>Compliance:</strong> Assessed using a maturity model, ranging from Initial (Level 1) to Optimized (Level 5).</li>
          <li><strong>Operational Efficiency:</strong> Scored based on automation, efficiency, and cloud management capabilities.</li>
          <li><strong>Scalability:</strong> Evaluated using capacity for future innovation and growth, including elastic scaling readiness.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Case Studies</h2>
        <p className="mb-4">
          Our approach to cloud adoption has proven successful across various industries. Below are examples of our impactful work:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li>
            <strong>Healthcare:</strong> A leading hospital group reduced costs by 35% while ensuring compliance with HIPAA by transitioning to a hybrid cloud model.
          </li>
          <li>
            <strong>Finance:</strong> A financial institution achieved 50% faster processing times by modernizing its infrastructure using cloud-native solutions.
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Risk Mitigation Plan</h2>
        <p className="mb-4">
          A comprehensive risk mitigation plan is crafted based on assessment findings, focusing on areas like regulatory compliance,
          security vulnerabilities, and operational risks. Our mitigation strategies are tailored to address specific industry regulations,
          ensuring a smooth and secure cloud transition.
        </p>
      </div>

      {/* Call to Action */}
      <CTAButton
        text="Get Your Readiness Assessment"
        subtext="Contact us to begin your tailored cloud adoption readiness assessment and receive an in-depth technical report."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}
    </div>
  );
};

export default ReadinessAssessment;
