import React from 'react';

const CloudAnalytics = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Cloud Analytics</h1>
      <p className="text-lg mb-4">
        Empower your business with data-driven insights using advanced cloud analytics.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Business Intelligence & Visualization:</strong> Dashboard Development and Real-Time Reporting.</li>
        <li><strong>Data Warehousing & Integration:</strong> Data Warehousing Solutions, ETL/ELT, and Data Integration.</li>
        <li><strong>Advanced Analytics:</strong> Predictive Analytics, Data Mining, and Customer Insights.</li>
        <li><strong>Big Data Analytics:</strong> Hadoop, Spark, Data Lake Analytics, and Data Processing.</li>
      </ul>
    </div>
  );
};

export default CloudAnalytics;

