import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import DataLakesWarehousing from './DataLakesWarehousing';

const DataArchitecturePage = () => {
  const [activeTab, setActiveTab] = useState('datalakeswarehousing');

  // Function to render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'datalakeswarehousing':
        return <DataLakesWarehousing />;
      default:
        return <DataLakesWarehousing />;
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Breadcrumb Navigation */}
      <nav className="text-sm mb-4">
        <Link to="/" className="text-gray-600 hover:text-blue-500">Home</Link> &gt;{' '}
        <Link to="/architecture" className="text-gray-600 hover:text-blue-500">Architecture</Link> &gt;{' '}
        <Link to="/architecture/data-architecture" className="text-gray-600 hover:text-blue-500">Data Architecture</Link>
      </nav>

      {/* Tab Navigation */}
      <div className="flex border-b mb-6">
        <button
          className={`p-4 ${
            activeTab === 'datalakeswarehousing' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('datalakeswarehousing')}
        >
          Data Lakes & Warehousing
        </button>
      </div>

      {/* Render Content Based on Active Tab */}
      <div>{renderContent()}</div>
    </div>
  );
};

export default DataArchitecturePage;
