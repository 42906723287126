import React, { useState } from 'react';
import CTAButton from '../components/CTAButton';
import ContactForm from '../components/ContactForm';

const AboutUs = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-4xl font-bold mb-6 text-center">About Us</h2>
      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Our Mission & Vision</h3>
        <p>
          At Invictus, our mission is to empower businesses with innovative technology solutions that drive transformation and
          deliver measurable success. We are committed to client success and industry leadership, creating a future where technology
          and business excellence converge seamlessly. Our vision is to be the most trusted partner for enterprises seeking
          to adopt, optimize, and transform their technology landscape.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Our Unique Value Proposition</h3>
        <p>
          Invictus stands apart with our dedication to innovation, customer-centric strategies, and technology leadership.
          We leverage deep industry insights, advanced AI-driven solutions, and cutting-edge technology to deliver tailored
          consulting services that solve real-world business challenges. Our proven success across finance, healthcare,
          retail, and manufacturing showcases our ability to provide strategic guidance and technology solutions
          that lead to outstanding business outcomes.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Consulting Expertise & Core Values</h3>
        <p>
          Our consulting expertise spans multiple domains, including:
        </p>
        <ul className="list-disc list-inside space-y-2 mb-4">
          <li>Cloud Adoption Strategy & Digital Transformation</li>
          <li>AI-Driven Solutions & Machine Learning</li>
          <li>Data Architecture & Cloud Analytics</li>
          <li>Industry-Specific Consulting (Finance, Healthcare, Retail, Manufacturing)</li>
          <li>Migration Services, DevOps, and Managed Services</li>
        </ul>
        <p>
          We uphold core values of <strong>Innovation</strong>, <strong>Integrity</strong>, and <strong>Excellence in Execution</strong>.
          Our goal is to deliver future-proof solutions that are reliable, scalable, and tailored to each client's unique needs.
        </p>
      </section>

      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Our Aspirations & Future Goals</h3>
        <p>
          At Invictus, we aim to drive transformation within key industry sectors by embracing emerging technologies and fostering
          innovation. Our future goals include expanding our AI-driven solutions portfolio, investing in IoT research, and
          continuing to lead the charge in digital transformation. We aspire to be the go-to consulting partner for businesses
          navigating the complexities of the digital era.
        </p>
      </section>

      {/* Call to Action */}
      <CTAButton
        text="Learn More About Invictus"
        subtext="Discover how Invictus can help transform your business with innovative technology solutions."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}

    </div>
  );
};

export default AboutUs;
