// File: src/pages/Resources.js

import React from 'react';
import { Link } from 'react-router-dom';
import { FaFileAlt, FaRegNewspaper, FaVideo, FaQuoteRight } from 'react-icons/fa';

const Resources = () => {
  return (
    <div className="container mx-auto p-6">
      {/* Breadcrumb Navigation */}
      <nav className="text-sm mb-4">
        <Link to="/" className="text-gray-600 hover:text-blue-500">Home</Link> &gt;{' '}
        <span className="text-gray-600">Resources</span>
      </nav>

      {/* Page Title */}
      <h1 className="text-4xl font-bold mb-6 text-center">Resources</h1>
      <p className="mb-8 text-gray-700">
        Stay updated with our latest insights, articles, and industry reports.
      </p>

      {/* Resource Categories */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {/* Blogs Card */}
        <div className="bg-white shadow-md rounded p-4 hover:shadow-lg transition duration-300">
          <FaRegNewspaper className="text-blue-500 text-4xl mb-3" />
          <h2 className="text-2xl font-semibold mb-2">Blogs</h2>
          <p className="text-gray-600 mb-4">
            Industry Insights, Technical Deep Dives, and Expert Opinions.
          </p>
          <Link to="/resources/blogs" className="text-blue-500 hover:text-blue-600">Explore Blogs &rarr;</Link>

          {/* New Blog Entry */}
          <div className="mt-4">
            <h3 className="text-xl font-bold mb-2">Latest Blog</h3>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <Link
                  to="/resources/blogs/impact-of-broadcom-acquisition"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Impact of Broadcom's Acquisition on VMware License Costs
                </Link>
              </li>
              <li>
                <Link
                  to="/resources/blogs/achieving-efficient-aws-management"
                  className="text-blue-500 hover:text-blue-600"
                >
                  Achieving Efficient AWS Cloud Management: A 12-Month Blueprint for Cost Optimization and Performance Gains
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Whitepapers Card */}
        <div className="bg-white shadow-md rounded p-4 hover:shadow-lg transition duration-300">
          <FaFileAlt className="text-green-500 text-4xl mb-3" />
          <h2 className="text-2xl font-semibold mb-2">Whitepapers</h2>
          <p className="text-gray-600 mb-4">
            Research Papers, Best Practices, and Industry Trend Analysis.
          </p>
          <Link to="/resources/whitepapers" className="text-blue-500 hover:text-blue-600">Download Whitepapers &rarr;</Link>

          {/* New Whitepaper Entry */}
          <div className="mt-4">
            <h3 className="text-xl font-bold mb-2">Latest Whitepaper</h3>
            <ul className="list-disc pl-4 space-y-2">
              <li>
                <Link
                  to="/resources/whitepaper/ai-driven-cloud-optimization"
                  className="text-blue-500 hover:text-blue-600"
                >
                  AI-Driven Cloud Optimization
                </Link>
              </li>
            </ul>
          </div>
        </div>

        {/* Webinars Card */}
        <div className="bg-white shadow-md rounded p-4 hover:shadow-lg transition duration-300">
          <FaVideo className="text-red-500 text-4xl mb-3" />
          <h2 className="text-2xl font-semibold mb-2">Webinars</h2>
          <p className="text-gray-600 mb-4">
            Training Sessions, Expert Panels, and Product Demos.
          </p>
          <Link to="/resources/webinars" className="text-blue-500 hover:text-blue-600">View Webinars &rarr;</Link>
        </div>

        {/* Client Testimonials Card */}
        <div className="bg-white shadow-md rounded p-4 hover:shadow-lg transition duration-300">
          <FaQuoteRight className="text-purple-500 text-4xl mb-3" />
          <h2 className="text-2xl font-semibold mb-2">Client Testimonials</h2>
          <p className="text-gray-600 mb-4">
            Case Studies, Success Stories, and Client Feedback.
          </p>
          <Link to="/resources/client-testimonials" className="text-blue-500 hover:text-blue-600">Read Testimonials &rarr;</Link>
        </div>
      </div>
    </div>
  );
};

export default Resources;
