import React, { useState } from 'react';
import CTAButton from '../components/CTAButton';
import ContactForm from '../components/ContactForm';
import { FaPhone, FaEnvelope, FaMapMarkerAlt, FaLinkedin, FaTwitter, FaFacebook } from 'react-icons/fa';

const Contact = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  // Contact information
  const contactDetails = [
    {
      type: 'General Information',
      email: 'info@invictus.com',
      phone: '+1 (469) 348-5583',
    },
    {
      type: 'Business Inquiries',
      email: 'business@invictus.com',
      phone: '+1 (469) 348-5583',
    },
    {
      type: 'Partnerships',
      email: 'partners@invictus.com',
      phone: '+1 (469) 348-5583',
    },
  ];

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-4xl font-bold mb-6 text-center">Contact Us</h2>
      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Get in Touch</h3>
        <p className="mb-6">
          We're here to help! Whether you have questions about our services, want to discuss a business opportunity,
          or explore a potential partnership, feel free to reach out. We look forward to connecting with you.
        </p>

        {/* Contact Details */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-8">
          {contactDetails.map((detail, index) => (
            <div key={index} className="bg-gray-100 p-4 rounded shadow">
              <h4 className="text-xl font-semibold mb-2">{detail.type}</h4>
              <div className="flex items-center mb-2">
                <FaEnvelope className="mr-2 text-gray-600" />
                <a href={`mailto:${detail.email}`} className="text-blue-500 hover:text-blue-700">
                  {detail.email}
                </a>
              </div>
              <div className="flex items-center mb-2">
                <FaPhone className="mr-2 text-gray-600" />
                <a href={`tel:${detail.phone}`} className="text-blue-500 hover:text-blue-700">
                  {detail.phone}
                </a>
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Office Location */}
      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Our Offices</h3>
        <div className="flex items-center mb-4">
          <FaMapMarkerAlt className="text-gray-600 mr-2" />
          <p>Invictus HQ, 10332 Offshore Dr, Irving, TX 75063, USA</p>
        </div>
      </section>

      {/* Call to Action */}
      <CTAButton
        text="Book a Consultation"
        subtext="Ready to discuss your next project? Book a consultation with our experts."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}

      {/* Social Media Links */}
      <section className="my-10">
        <h3 className="text-2xl font-bold mb-4">Connect with Us</h3>
        <div className="flex space-x-6">
          <a href="https://www.linkedin.com/company/invictus" target="_blank" rel="noopener noreferrer">
            <FaLinkedin className="text-gray-600 hover:text-blue-500 text-2xl" />
          </a>
          <a href="https://twitter.com/invictus" target="_blank" rel="noopener noreferrer">
            <FaTwitter className="text-gray-600 hover:text-blue-400 text-2xl" />
          </a>
          <a href="https://facebook.com/invictus" target="_blank" rel="noopener noreferrer">
            <FaFacebook className="text-gray-600 hover:text-blue-600 text-2xl" />
          </a>
        </div>
      </section>

      {/* Newsletter Signup */}
      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Stay Updated</h3>
        <p className="mb-4">
          Subscribe to our newsletter to receive the latest updates, insights, and resources from Invictus.
        </p>
        <form className="flex flex-col md:flex-row gap-4">
          <input
            type="email"
            placeholder="Enter your email address"
            className="p-3 rounded border border-gray-300 focus:outline-none focus:border-blue-500"
          />
          <button
            type="submit"
            className="bg-blue-600 text-white px-6 py-3 rounded hover:bg-blue-700 transition"
          >
            Subscribe
          </button>
        </form>
      </section>

      {/* FAQ or Additional Resources (Optional) */}
      <section className="mb-8">
        <h3 className="text-2xl font-bold mb-4">Frequently Asked Questions</h3>
        <div className="bg-gray-100 p-4 rounded shadow">
          <h4 className="text-lg font-semibold mb-2">How can I get a quote?</h4>
          <p>
            You can request a quote by filling out our contact form above or reaching out directly to
            <a href="mailto:business@invictus.com" className="text-blue-500 hover:text-blue-700 ml-1">
              business@invictus.com
            </a>.
          </p>
        </div>
      </section>
    </div>
  );
};

export default Contact;
