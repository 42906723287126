import React from 'react';

const HeroSection = () => {
  return (
    <div className="bg-gradient-to-r from-gray-900 via-black to-gray-900 text-white py-20">
      <div className="container mx-auto text-center">
        {/* Headline */}
        <h1 className="text-5xl md:text-6xl font-bold mb-6">
          Transforming Cloud Innovation
        </h1>

        {/* Subheadline */}
        <p className="text-lg md:text-2xl max-w-3xl mx-auto mb-10">
          Experience seamless cloud services, AI-driven solutions, and industry-specific expertise. We empower enterprises to thrive in a digital-first world.
        </p>

        {/* Call to Action */}
        <div>
          <a
            href="#services"
            className="bg-white text-black py-3 px-8 rounded-full font-semibold hover:bg-gray-200 transition duration-300"
          >
            Discover Our Solutions
          </a>
        </div>
      </div>
    </div>
  );
};

export default HeroSection;
