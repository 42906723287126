// File: src/pages/Blogs/BlogsPage.js

import React from 'react';
import { Link } from 'react-router-dom';

const BlogsPage = () => {
  return (
    <div className="container mx-auto p-6">
      {/* Breadcrumb Navigation */}
      <nav className="text-sm mb-4">
        <Link to="/" className="text-gray-600 hover:text-blue-500">Home</Link> &gt;{' '}
        <Link to="/resources" className="text-gray-600 hover:text-blue-500">Resources</Link> &gt;{' '}
        <span className="text-gray-600">Blogs</span>
      </nav>

      {/* Page Title */}
      <h1 className="text-4xl font-bold mb-6 text-center">Blogs</h1>
      <p className="mb-8 text-gray-700">
        Explore our latest blogs covering industry insights, technical guides, and expert opinions.
      </p>

      {/* List of Blogs */}
      <div className="space-y-6">
        <div className="bg-white shadow-md rounded p-4 hover:shadow-lg transition duration-300">
          <h2 className="text-2xl font-bold mb-2">
            <Link to="/resources/blogs/impact-of-broadcom-acquisition" className="text-blue-500 hover:text-blue-600">
              Impact of Broadcom's Acquisition on VMware License Costs
            </Link>
          </h2>
          <p className="text-gray-600">
            Explore how Broadcom's acquisition impacts VMware costs and how AWS provides alternatives.
          </p>
        </div>
        {/* Add more blog entries here as needed */}
        <div className="bg-white shadow-md rounded p-4 hover:shadow-lg transition duration-300">
          <h2 className="text-2xl font-bold mb-2">
            <Link to="/resources/blogs/achieving-efficient-aws-management" className="text-blue-500 hover:text-blue-600">
              Achieving Efficient AWS Cloud Management: A 12-Month Blueprint for Cost Optimization and Performance Gains
            </Link>
          </h2>
          <p className="text-gray-600">
            Discover Invictus’s step-by-step 12-month plan to optimize AWS cloud costs and performance, maximizing your cloud ROI.
          </p>
        </div>
      </div>
    </div>
  );
};

export default BlogsPage;
