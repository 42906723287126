import React from 'react';

const DevOps = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">DevOps</h1>
      <p className="text-lg mb-4">
        Accelerate your development lifecycle with streamlined DevOps solutions.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>CI/CD Pipelines:</strong> Pipeline Design, GitOps, and Automated Testing & Deployment.</li>
        <li><strong>Infrastructure Management:</strong> Containerization, Configuration, and Serverless Deployments.</li>
        <li><strong>Monitoring & Observability:</strong> Logging, Monitoring, Alerting, and Performance Optimization.</li>
        <li><strong>DevSecOps:</strong> Security in CI/CD, Vulnerability Scanning, and Compliance Automation.</li>
      </ul>
    </div>
  );
};

export default DevOps;

