import React, { useState } from 'react';
import CTAButton from '../components/CTAButton';
import ContactForm from '../components/ContactForm';

import { FaBriefcase, FaUsers, FaLaptopCode } from 'react-icons/fa';

const Careers = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  const jobOpenings = [
    {
      title: 'Software Engineer',
      department: 'Engineering',
      location: 'San Francisco, CA (Remote Available)',
      description: 'Join our engineering team to build cutting-edge cloud solutions and AI-driven tools. We look for innovative minds with a passion for technology and problem-solving.',
    },
    {
      title: 'Sales Executive',
      department: 'Sales',
      location: 'New York, NY',
      description: 'We seek motivated and results-driven sales professionals to drive customer acquisition and business growth. Bring your enthusiasm for technology and client relationships.',
    },
    {
      title: 'Marketing Specialist',
      department: 'Marketing',
      location: 'Remote',
      description: 'Be a part of our marketing team and shape the brand voice of Invictus. We value creativity, data-driven strategies, and excellent communication skills.',
    },
  ];

  return (
    <div className="container mx-auto p-6">
      <h2 className="text-4xl font-bold mb-6 text-center">Careers at Invictus</h2>

      {/* Company Culture Section */}
      <section className="mb-10">
        <h3 className="text-2xl font-bold mb-4">Join Our Team</h3>
        <p className="mb-6">
          At Invictus, we believe in empowering our team members to grow and thrive in a dynamic and innovative environment.
          We are committed to fostering a culture of collaboration, creativity, and continuous learning.
        </p>
        <p className="mb-6">
          Our mission is to deliver top-notch cloud and AI solutions while nurturing a supportive workplace where talent can flourish.
          We value diversity, inclusivity, and excellence, and we strive to be a workplace that inspires individuals to reach their full potential.
        </p>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 mt-8">
          <div className="bg-gray-100 p-6 rounded shadow">
            <FaLaptopCode className="text-4xl text-blue-600 mb-4" />
            <h4 className="text-xl font-semibold mb-2">Innovation at the Core</h4>
            <p>Work on cutting-edge technology projects that redefine industries and deliver impact.</p>
          </div>
          <div className="bg-gray-100 p-6 rounded shadow">
            <FaUsers className="text-4xl text-green-600 mb-4" />
            <h4 className="text-xl font-semibold mb-2">Teamwork and Collaboration</h4>
            <p>We thrive on teamwork and foster an inclusive environment where every voice is heard.</p>
          </div>
          <div className="bg-gray-100 p-6 rounded shadow">
            <FaBriefcase className="text-4xl text-purple-600 mb-4" />
            <h4 className="text-xl font-semibold mb-2">Career Growth</h4>
            <p>We provide ample opportunities for career advancement, skill development, and mentorship.</p>
          </div>
        </div>
      </section>

      {/* Benefits and Perks Section */}
      <section className="mb-10">
        <h3 className="text-2xl font-bold mb-4">Why Work at Invictus?</h3>
        <ul className="list-disc list-inside mb-6">
          <li>Flexible work hours and remote work options.</li>
          <li>Comprehensive health and wellness benefits.</li>
          <li>Opportunities for professional development and continuous learning.</li>
          <li>Inclusive company culture that values diversity and innovation.</li>
          <li>Competitive compensation and performance-based incentives.</li>
        </ul>
      </section>

      {/* Current Job Openings Section
      <section className="mb-10">
        <h3 className="text-2xl font-bold mb-4">Open Positions</h3>
        {jobOpenings.length > 0 ? (
          <div className="space-y-6">
            {jobOpenings.map((job, index) => (
              <div key={index} className="bg-white p-6 rounded shadow">
                <h4 className="text-xl font-semibold mb-2">{job.title}</h4>
                <p className="text-gray-600 mb-1"><strong>Department:</strong> {job.department}</p>
                <p className="text-gray-600 mb-4"><strong>Location:</strong> {job.location}</p>
                <p className="mb-4">{job.description}</p>

                <CTAButton
                  text="Apply Now"
                  subtext={`Interested in the ${job.title}? Apply and join our team.`}
                  bgColor="bg-blue-600"
                  hoverColor="bg-blue-700"
                  onClick={() => window.location.href = `mailto:hr@invictus.com?subject=Application for ${encodeURIComponent(job.title)}`}
                />
              </div>
            ))}
          </div>
        ) : (
          <p className="text-gray-600">Currently, we do not have any open positions. Please check back later.</p>
        )}
      </section>
      */}

      {/* Employee Testimonials or Stories
      <section className="mb-10">
        <h3 className="text-2xl font-bold mb-4">Hear from Our Team</h3>
        <blockquote className="border-l-4 border-blue-600 pl-4 italic text-gray-700">
          "Invictus has been an incredible place for my professional growth. The collaborative culture, the innovative projects,
          and the supportive team make this company truly unique." — Sarah L., Senior Software Engineer
        </blockquote>
      </section>
      */}
    </div>
  );
};

export default Careers;
