import React from 'react';

const AI_ML = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">AI/ML</h1>
      <p className="text-lg mb-4">
        Leverage advanced AI and Machine Learning solutions to unlock the potential of your data.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>AI Strategy & Consulting:</strong> AI Readiness, Use Case Discovery, and Roadmap Development.</li>
        <li><strong>Machine Learning Solutions:</strong> Predictive Analytics, NLP, and Computer Vision.</li>
        <li><strong>AI Model Development & Deployment:</strong> Custom Models, Training, Tuning, and MLOps.</li>
        <li><strong>Data Science Services:</strong> Data Engineering, Visualization, and Advanced Analytics.</li>
      </ul>
    </div>
  );
};

export default AI_ML;

