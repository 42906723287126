import React from 'react';

const Training = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Training & Enablement</h1>
      <p className="text-lg mb-4">
        Equip your team with the skills needed for the future with expert-led training.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Cloud Training:</strong> Cloud Basics, Advanced Cloud Architect Training, and Cloud Security.</li>
        <li><strong>DevOps Training:</strong> CI/CD Workshops, Kubernetes, and Infrastructure as Code.</li>
        <li><strong>AI/ML Training:</strong> Data Science, Advanced AI Techniques, and MLOps.</li>
        <li><strong>Customized Workshops:</strong> Hands-On Labs, Industry-Specific Use Cases, and Hackathons.</li>
      </ul>
    </div>
  );
};

export default Training;

