// File: src/pages/Strategy/CostOptimization/CostOptimizationPage.js

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import CostOptimizationOverview from './CostOptimizationOverview';
import CostAnalysisAssessment from './CostAnalysisAssessment';
import FinancialModelingBudgeting from './FinancialModelingBudgeting';
import CloudCostGovernance from './CloudCostGovernance';

const CostOptimizationPage = () => {
  const [activeTab, setActiveTab] = useState('overview');

  // Function to render content based on active tab
  const renderContent = () => {
    switch (activeTab) {
      case 'overview':
        return <CostOptimizationOverview />;
      case 'analysis':
        return <CostAnalysisAssessment />;
      case 'financialModeling':
        return <FinancialModelingBudgeting />;
      case 'costGovernance':
        return <CloudCostGovernance />;
      default:
        return <CostOptimizationOverview />;
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Breadcrumb Navigation */}
      <nav className="text-sm mb-4">
        <Link to="/" className="text-gray-600 hover:text-blue-500">Home</Link> &gt;{' '}
        <Link to="/strategy" className="text-gray-600 hover:text-blue-500">Strategy</Link> &gt;{' '}
        <Link to="/strategy/cost-optimization" className="text-gray-600 hover:text-blue-500">Cost Optimization</Link>
      </nav>

      {/* Tab Navigation */}
      <div className="flex border-b mb-6">
        <button
          className={`p-4 ${
            activeTab === 'overview' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('overview')}
        >
          Overview
        </button>
        <button
          className={`p-4 ${
            activeTab === 'analysis' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('analysis')}
        >
          Cost Analysis & Assessment
        </button>
        <button
          className={`p-4 ${
            activeTab === 'financialModeling' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('financialModeling')}
        >
          Financial Modeling & Budgeting
        </button>
        <button
          className={`p-4 ${
            activeTab === 'costGovernance' ? 'border-b-2 border-blue-600 text-blue-600' : 'text-gray-600'
          }`}
          onClick={() => setActiveTab('costGovernance')}
        >
          Cloud Cost Governance
        </button>
      </div>

      {/* Render Content Based on Active Tab */}
      <div>{renderContent()}</div>
    </div>
  );
};

export default CostOptimizationPage;
