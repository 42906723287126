import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaSearch, FaBars, FaTimes } from 'react-icons/fa'; // FontAwesome Icons

const Navbar = ({ pages }) => {
  const [openDropdown, setOpenDropdown] = useState(null);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [filteredResults, setFilteredResults] = useState([]);
  const navigate = useNavigate();

  let timeoutId = null; // To store timeout for mouse leave
  const location = useLocation(); // To detect route changes

  const handleMouseEnter = (dropdown) => {
    if (timeoutId) {
      clearTimeout(timeoutId); // Cancel closing if mouse enters again
    }
    setOpenDropdown(dropdown);
  };

  const handleMouseLeave = () => {
    timeoutId = setTimeout(() => {
      setOpenDropdown(null);
    }, 200); // Adjust delay as necessary (200ms is a good starting point)
  };

  // Function to handle closing dropdown
  const closeDropdown = () => {
    setOpenDropdown(null);
  };

  // Close dropdown when location changes (new page loads)
  React.useEffect(() => {
    closeDropdown();
  }, [location]);

  const handleMenuToggle = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  const closeMenu = () => {
    setMobileMenuOpen(false);
  };

  const handleSearchInput = (event) => {
    const query = event.target.value;
    setSearchQuery(query);

    // Simple filtering (update based on your content structure)
    if (query) {
      const results = pages.filter((page) =>
        page.title.toLowerCase().includes(query.toLowerCase())
      );
      setFilteredResults(results);
    } else {
      setFilteredResults([]);
    }
  };

  // Handle Search Item Click
  const handleSearchItemClick = (path) => {
    setSearchOpen(false);
    setSearchQuery(''); // Clear search input
    setFilteredResults([]); // Clear results
    navigate(path); // Navigate to the selected page
  };

  return (
    <nav className="bg-black text-white p-5 shadow-md sticky top-0 z-50">
      <div className="container mx-auto flex justify-between items-center">
        {/* Logo Section */}
        <div className="text-2xl font-bold">
          <Link to="/">Invictus</Link>
        </div>

        {/* Desktop Navigation Links */}
        <div className="hidden md:flex space-x-6">
          {/* Services Mega Menu */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter('services')}
            onMouseLeave={handleMouseLeave}
          >
            <button className="hover:text-gray-400 focus:outline-none">
              Services
            </button>
            {openDropdown === 'services' && (
              <div className="absolute left-0 mt-2 w-80 bg-white text-black rounded shadow-lg grid grid-cols-2 p-4">
                <div>
                  <h3 className="font-bold">Strategy</h3>
                  <Link to="/strategy/cloud-adoption" className="block px-4 py-2 hover:bg-gray-200">Cloud Adoption</Link>
                  <Link to="/strategy/cost-optimization" className="block px-4 py-2 hover:bg-gray-200">Cost Optimization</Link>
                  <Link to="/strategy/digital-transformation" className="block px-4 py-2 hover:bg-gray-200">Digital Transformation</Link>
                </div>
                <div>
                  <h3 className="font-bold">Architecture</h3>
                  <Link to="/architecture/cloud-design" className="block px-4 py-2 hover:bg-gray-200">Cloud Design</Link>
                  <Link to="/architecture/data-architecture" className="block px-4 py-2 hover:bg-gray-200">Data Architecture</Link>
                  <Link to="/architecture" className="block px-4 py-2 hover:bg-gray-200">Security Architecture</Link>
                </div>
              </div>
            )}
          </div>

          {/* Solutions Mega Menu */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter('solutions')}
            onMouseLeave={handleMouseLeave}
          >
            <button className="hover:text-gray-400 focus:outline-none">
              Solutions
            </button>
            {openDropdown === 'solutions' && (
              <div className="absolute left-0 mt-2 w-80 bg-white text-black rounded shadow-lg grid grid-cols-2 p-4">
                <div>
                  <h3 className="font-bold">AI/ML</h3>
                  <Link to="/ai-ml" className="block px-4 py-2 hover:bg-gray-200">Predictive Analytics</Link>
                  <Link to="/ai-ml" className="block px-4 py-2 hover:bg-gray-200">Data Science</Link>
                </div>
                <div>
                  <h3 className="font-bold">DevOps</h3>
                  <Link to="/devops" className="block px-4 py-2 hover:bg-gray-200">CI/CD Pipelines</Link>
                  <Link to="/devops" className="block px-4 py-2 hover:bg-gray-200">Infrastructure Management</Link>
                </div>
              </div>
            )}
          </div>

          {/* Industry
          <Link to="/industry-solutions" className="hover:text-gray-400">Industries</Link>
          */}
          {/* Industries Dropdown */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter('industries')}
            onMouseLeave={handleMouseLeave}
          >
            <button className="hover:text-gray-700 focus:outline-none">Industries</button>
            {openDropdown === 'industries' && (
              <ul className="absolute bg-white text-black shadow-lg mt-2 py-2 w-48 rounded-lg">
                <li className="px-4 py-2 hover:bg-gray-200">
                  <Link to="/industries-healthcare">Healthcare</Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-200">
                  <Link to="/industries-finance">Finance</Link>
                </li>
                <li className="px-4 py-2 hover:bg-gray-200">
                  <Link to="/industries-retail">Retail</Link>
                </li>
              </ul>
            )}
          </div>

          <Link to="/resources" className="hover:text-gray-400">Resources</Link>

          {/* More Dropdown for Additional Links */}
          <div
            className="relative"
            onMouseEnter={() => handleMouseEnter('more')}
            onMouseLeave={handleMouseLeave}
          >
            <button className="hover:text-gray-400 focus:outline-none">
              More
            </button>
            {openDropdown === 'more' && (
              <div className="absolute left-0 mt-2 w-48 bg-white text-black rounded shadow-lg">
                <Link to="/about" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdown}>About Us</Link>
                <Link to="/contact" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdown}>Contact</Link>
                <Link to="/careers" className="block px-4 py-2 hover:bg-gray-200" onClick={closeDropdown}>Careers</Link>
              </div>
            )}
          </div>

          {/* Search Icon */}
          <button
            onClick={() => setSearchOpen(!searchOpen)}
            className="hover:text-gray-400 focus:outline-none"
          >
            <FaSearch />
          </button>

          {/* Search Bar */}
          {searchOpen && (
            <div className="absolute right-0 mt-2 bg-white text-black rounded shadow-lg p-2">
              <input
                type="text"
                placeholder="Search..."
                value={searchQuery}
                onChange={handleSearchInput}
                className="bg-gray-100 p-2 rounded w-64"
              />
              {/* Display filtered results */}
              {filteredResults.length > 0 && (
                <div className="mt-2 bg-gray-200 rounded shadow-md max-h-64 overflow-y-auto">
                  {filteredResults.map((result) => (
                    <div
                      key={result.id}
                      onClick={() => handleSearchItemClick(result.path)}
                      className="px-4 py-2 cursor-pointer hover:bg-gray-300"
                    >
                      {result.title}
                    </div>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>

        {/* Mobile Hamburger Menu */}
        <div className="md:hidden">
          {/*
          <button onClick={() => setMobileMenuOpen(!mobileMenuOpen)}>
            <FaBars className="text-xl hover:text-gray-400" />
          </button>
          */}
          <button onClick={handleMenuToggle} className="md:hidden text-2xl">
            {mobileMenuOpen ? <FaTimes /> : <FaBars />}
          </button>
        </div>
      </div>

      {/* Mobile Navigation */}
      {mobileMenuOpen && (
        <div className="md:hidden absolute left-0 top-0 w-full h-screen bg-black text-white p-6">
          <button onClick={() => setMobileMenuOpen(false)} className="text-right mb-4 text-2xl">✕</button>
          <div className="space-y-4">
            <Link to="/strategy" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700">Strategy</Link>
            <Link to="/architecture" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700">Architecture</Link>
            <Link to="/ai-ml" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700">AI/ML</Link>
            <Link to="/devops" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700">DevOps</Link>
            <Link to="/industry-solutions" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700">Industries</Link>
            <Link to="/resources" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700">Resources</Link>
            <Link to="/about" onClick={closeMenu} className="block px-4 py-2 hover:bg-gray-700">About Us</Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navbar;
