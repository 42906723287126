import React from 'react';

const IndustrySolutions = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Industry-Specific Solutions</h1>
      <p className="text-lg mb-4">
        Tailored cloud solutions for industry-specific challenges and needs.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Finance:</strong> Risk Management, Compliance, and Fraud Prevention.</li>
        <li><strong>Healthcare:</strong> Telemedicine, Patient Data Analytics, and Compliance.</li>
        <li><strong>Retail:</strong> Customer Personalization, Inventory Management, and E-commerce Optimization.</li>
        <li><strong>Manufacturing:</strong> Predictive Maintenance, Smart Manufacturing, and Supply Chain Optimization.</li>
      </ul>
    </div>
  );
};

export default IndustrySolutions;

