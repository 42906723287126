import React from 'react';

const Architecture = () => {
  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Architecture</h1>
      <p className="text-lg mb-4">
        Explore cutting-edge cloud architecture solutions that drive innovation and performance.
      </p>
      <ul className="list-disc list-inside mb-6">
        <li><strong>Cloud Architecture Design:</strong> Cloud-Native, Hybrid, and Multi-Cloud strategies.</li>
        <li><strong>Data Architecture:</strong> Data Lakes, Warehousing, Governance, and Big Data Solutions.</li>
        <li><strong>Security Architecture:</strong> Zero Trust, IAM, and Compliance Monitoring.</li>
        <li><strong>Application Architecture:</strong> Microservices, API Management, and DevSecOps Integration.</li>
      </ul>
    </div>
  );
};

export default Architecture;

