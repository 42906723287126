import React, { useState } from 'react';
import CTAButton from '../../../components/CTAButton';
import ContactForm from '../../../components/ContactForm';
import { Link } from 'react-router-dom';

const HybridCloudArchitecture = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  return (
    <div className="container mx-auto p-6">
      {/* Page Title */}
      <h1 className="text-4xl font-bold mb-6 text-center">Hybrid Cloud Architecture Design</h1>
      <p className="mb-8 text-gray-700">
        Invictus delivers tailored Hybrid Cloud Architecture solutions that bridge on-premise infrastructure with public clouds like AWS and Azure, optimizing costs, scalability, and security for enterprises across diverse industries.
      </p>

      {/* Content Sections */}
      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Core Principles of Hybrid Cloud Architecture</h2>
        <p className="text-gray-700 mb-4">
          Our Hybrid Cloud Architecture is built on core principles designed to provide seamless integration, scalability, and security across on-premise and cloud environments. Key considerations include:
        </p>
        <ul className="list-disc list-inside text-gray-700">
          <li><strong>Security:</strong> Robust security measures and compliance frameworks ensure data protection across environments.</li>
          <li><strong>Scalability:</strong> Dynamic scaling allows businesses to leverage on-demand resources in public clouds, reducing costs and improving agility.</li>
          <li><strong>Interoperability:</strong> Invictus ensures smooth interoperability between systems, minimizing disruptions and maintaining operational consistency.</li>
          <li><strong>Data Management:</strong> Effective data integration and governance streamline operations and improve data availability for decision-making.</li>
          <li><strong>Workload Distribution:</strong> Workload flexibility allows critical applications to run in the best-suited environments, optimizing performance and cost.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Invictus’s Expertise in Hybrid Cloud Solutions</h2>
        <p className="text-gray-700 mb-4">
          At Invictus, we bring deep expertise in Hybrid Cloud Architecture, with a focus on integrating AWS, Azure, and on-premise environments. Our architects work closely with clients to create customized solutions that meet unique business needs.
        </p>
        <ul className="list-disc list-inside text-gray-700">
          <li><strong>Cost Reduction:</strong> By balancing cloud and on-premise resources, Invictus designs solutions that optimize costs and reduce capital expenditures.</li>
          <li><strong>Regulatory Compliance:</strong> Our frameworks support industry-specific compliance requirements, ensuring secure and compliant operations.</li>
          <li><strong>Operational Resilience:</strong> Invictus’s hybrid architectures are built for resilience, allowing continuous operations even in case of cloud or on-premise failures.</li>
          <li><strong>Performance Optimization:</strong> Through intelligent workload distribution, we ensure high performance for critical applications, leveraging the best capabilities of each environment.</li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Industry-Specific Use Cases and Success Stories</h2>
        <p className="text-gray-700 mb-4">
          Invictus has successfully implemented Hybrid Cloud Architecture solutions for clients across various industries. Here are a few examples of how we’ve delivered value through hybrid solutions:
        </p>
        <ul className="list-disc list-inside text-gray-700">
          <li>
            <strong>Finance:</strong> A financial services client achieved compliance with data residency regulations by utilizing a hybrid model that kept sensitive data on-premise, while leveraging cloud resources for analytics workloads.
          </li>
          <li>
            <strong>Healthcare:</strong> Invictus helped a healthcare provider integrate their on-premise EHR system with cloud-based applications, enabling better data sharing and access to advanced analytics while maintaining HIPAA compliance.
          </li>
          <li>
            <strong>Retail:</strong> For a large retail chain, we implemented a hybrid solution that optimized supply chain management by integrating cloud resources with on-premise systems, improving scalability and reducing IT costs.
          </li>
        </ul>
      </section>

      <section className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Addressing Hybrid Cloud Challenges</h2>
        <p className="text-gray-700 mb-4">
          Hybrid Cloud comes with its own set of challenges, from managing complexity to ensuring security across environments. Invictus tackles these with:
        </p>
        <ul className="list-disc list-inside text-gray-700">
          <li><strong>Security and Compliance:</strong> By implementing a zero-trust security model, Invictus ensures consistent security policies across on-premise and cloud environments.</li>
          <li><strong>Data Integration:</strong> Using advanced data integration tools, we enable seamless data flow and management across environments.</li>
          <li><strong>Unified Management:</strong> Invictus provides unified visibility into hybrid environments, simplifying monitoring, management, and governance.</li>
        </ul>
      </section>

      {/* Call to Action */}
      <CTAButton
        text="Ready to Transform Your Business with Hybrid Cloud?"
        subtext="Our cloud architects at Invictus design tailored Hybrid Cloud solutions to enhance security, optimize costs, and boost resilience. Contact us to start your cloud transformation."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}
    </div>
  );
};

export default HybridCloudArchitecture;
