import React, { useState } from 'react';
import CTAButton from '../../../components/CTAButton';
import ContactForm from '../../../components/ContactForm';

const Overview = () => {
  const [showContactForm, setShowContactForm] = useState(false);

  const handleConsultationClick = () => {
    setShowContactForm(true); // Open the contact form
  };

  const handleCloseForm = () => {
    setShowContactForm(false); // Close the contact form
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-4xl font-bold mb-6 text-left">Cloud Adoption Overview</h1>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Understanding Cloud Adoption</h2>
        <p className="mb-4">
          Cloud adoption involves the process of transitioning an organization's digital assets, data, IT resources, and applications
          to cloud environments. This strategic move offers numerous benefits, including enhanced scalability, cost efficiency,
          and faster innovation cycles.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Key Benefits of Cloud Adoption</h2>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Scalability:</strong> Cloud platforms allow businesses to scale resources up or down as needed, catering to fluctuating demands.</li>
          <li><strong>Cost Efficiency:</strong> Shift from CapEx to OpEx, reducing infrastructure costs and enabling better budget management.</li>
          <li><strong>Innovation:</strong> Accelerate time-to-market with access to advanced tools, AI, analytics, and other cloud-native technologies.</li>
          <li><strong>Operational Agility:</strong> Faster deployment and easier management of infrastructure through automation and orchestration.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Challenges in Cloud Adoption</h2>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Security Concerns:</strong> Addressing data privacy, identity management, and threat detection in a cloud environment.</li>
          <li><strong>Cost Management:</strong> Controlling cloud spending while ensuring resource optimization and avoiding budget overruns.</li>
          <li><strong>Complex Migration:</strong> Migrating legacy systems to the cloud without disrupting business operations.</li>
          <li><strong>Compliance & Regulation:</strong> Navigating industry-specific compliance requirements, such as HIPAA, GDPR, or PCI-DSS.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Industry-Specific Insights</h2>
        <p className="mb-4">
          Different industries face unique challenges and opportunities in cloud adoption. Below are some insights tailored to key sectors:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Healthcare:</strong> Cloud enables better data interoperability, secure storage, and AI-driven diagnostic tools, while managing HIPAA compliance.</li>
          <li><strong>Finance:</strong> Enhanced security, faster transaction processing, and real-time analytics are driving cloud adoption in banking and finance.</li>
          <li><strong>Manufacturing:</strong> Industrial IoT, edge computing, and AI are transforming manufacturing processes through cloud adoption.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Major Cloud Providers & Technical Comparison</h2>
        <p className="mb-4">
          A comparison of the major cloud providers—AWS, Azure, and Google Cloud—highlights their technical strengths and market share:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>AWS:</strong> Market leader with a vast service portfolio, strong developer community, and advanced AI/ML capabilities.</li>
          <li><strong>Azure:</strong> Dominant in the enterprise space, known for seamless integration with Microsoft products, and strong in hybrid cloud solutions.</li>
          <li><strong>Google Cloud:</strong> Renowned for data analytics, AI, and Kubernetes leadership, offering competitive pricing for data-intensive applications.</li>
        </ul>
        <p className="mb-4">
          Each provider offers unique advantages, making the choice dependent on specific business needs and industry requirements.
        </p>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Emerging Technologies & Trends</h2>
        <p className="mb-4">
          Recent trends like AI, edge computing, and serverless architectures are redefining cloud adoption. These technologies are
          driving changes in various industries:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>AI & Machine Learning:</strong> Cloud platforms provide powerful AI tools for predictive analytics, automation, and machine learning.</li>
          <li><strong>Edge Computing:</strong> Bringing computation closer to the source, reducing latency, and enhancing real-time processing.</li>
          <li><strong>Serverless:</strong> Enabling developers to focus on code without managing underlying infrastructure, optimizing costs and deployment speed.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Cloud Strategies & Best Practices</h2>
        <p className="mb-4">
          Successful cloud adoption requires a well-defined strategy that includes best practices such as:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Security:</strong> Implement a zero-trust model, strong encryption, and continuous monitoring to safeguard data.</li>
          <li><strong>Cost Management:</strong> Use cloud financial management tools (e.g., FinOps) to monitor and optimize cloud spending.</li>
          <li><strong>Migration Strategy:</strong> Choose between lift-and-shift, refactoring, or rebuilding based on organizational needs and resources.</li>
          <li><strong>Cloud-Native vs. Hybrid/Multi-Cloud:</strong> Assess the pros and cons of cloud-native deployments versus hybrid or multi-cloud strategies for flexibility and resilience.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Case Studies & Success Stories</h2>
        <p className="mb-4">
          Here are structured case studies that illustrate diverse cloud adoption scenarios:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li>
            <strong>Small Business:</strong> A startup successfully moved from on-premises to AWS, reducing infrastructure costs by 40% and improving scalability.
          </li>
          <li>
            <strong>Large Enterprise:</strong> A global manufacturer transitioned to a hybrid cloud model using Azure, achieving a 60% reduction in downtime.
          </li>
          <li>
            <strong>Healthcare:</strong> A mid-sized hospital leveraged Google Cloud for AI-based diagnostics, enhancing patient care and reducing costs by 30%.
          </li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Common Myths & Misconceptions</h2>
        <p className="mb-4">
          Cloud adoption comes with myths that can deter organizations. Here are some misconceptions and the reality behind them:
        </p>
        <ul className="list-disc ml-8 space-y-2">
          <li><strong>Myth:</strong> Cloud is inherently insecure.<br /><strong>Reality:</strong> With proper security measures, cloud environments can be more secure than traditional setups.</li>
          <li><strong>Myth:</strong> Cloud always saves money.<br /><strong>Reality:</strong> Without effective cost management, cloud costs can spiral, making planning essential.</li>
        </ul>
      </div>

      <div className="mb-8">
        <h2 className="text-2xl font-semibold mb-4">Future Outlook & Predictions</h2>
        <p className="mb-4">
          The future of cloud adoption will be driven by emerging technologies, regulatory shifts, and changing market demands.
          Key trends to watch include the rise of multi-cloud environments, AI-integrated operations, and the increased adoption
          of edge computing for latency-sensitive applications.
        </p>
      </div>

      {/* Call to Action */}
      <CTAButton
        text="Start Your Cloud Journey"
        subtext="Connect with Invictus to explore how cloud adoption can transform your business. Our experts are ready to guide you."
        bgColor="bg-blue-600"
        hoverColor="bg-blue-700"
        onClick={handleConsultationClick}
      />

      {/* Contact Form Modal */}
      {showContactForm && <ContactForm onClose={handleCloseForm} />}
    </div>
  );
};


export default Overview;
